import React, { Component,Suspense  } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

//import classes from './Layout.module.css'
import Toolbar from '../../component/Navigation/toolbar/Toolbar';
import Contacts from '../../Containers/sections/Contacts/Contacts';
import Resume from '../../Containers/sections/Resume/Resume';

import Portfolio from '../../Containers/sections/Portfolio/Portfolio';

// import Skills from '../../Containers/sections/Skills/Skills';
// import Projects from '../../Containers/sections/Projects/Projects';

//import classes from './Layout.module.css';

const Skills = React.lazy(() => import ('../../Containers/sections/Skills/Skills')) 
const Projects = React.lazy(() => import ('../../Containers/sections/Projects/Projects'))


class Layout extends Component {

    

    render() {

        return (
            <React.Fragment>
                <Toolbar />
                    <Routes>
                    <Route path='/home' element={<Resume />} />
                    <Route
                        path='/skills'
                        element={
                        <Suspense fallback={<div>Loading...</div>}>
                            <Skills />
                        </Suspense>
                        }
                    />
                    <Route
                        path='/projects'
                        element={
                        <Suspense fallback={<div>Loading...</div>}>
                            <Projects />
                        </Suspense>
                        }
                    />
                    <Route path='/portfolio' element={<Portfolio />} />
                    <Route path='/contacts' element={<Contacts />} />
                    <Route path='/' element={<Resume />} />
                    <Route path='*' element={<Navigate to="/" />} />
                </Routes>
          </React.Fragment>
        )
    }
};



export default Layout;