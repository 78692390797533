import React,{ useState } from 'react';
import {useSpring, animated, useTrail} from 'react-spring'


import ContactForm from '../ContactForm/ContactForm';
import images from '../../../../assets/images/networks';
import classes from './Contact.module.css'
import ArrowImg from '../../../../assets/images/here1.gif'
import Oim from '../../../../assets/images/oim.png';
    
 
const Contact = (porps) => {

    const [items] = useState([
      
        {
            id : '1',
            img : 'linked',
            positionsX : [0, 10, 20 , 30 ,33,36,38,40,42],
            //  positionsX : [0,300, 600,900 ,1050,1200,1300,1400,1400],
            rotates : [40,80,120,160,200,240,280,350,360 ],
            link :'https://www.linkedin.com/in/hamza-bounouidar-331a2b43/',
            title : "Let's socialize"
        },
        {
            id : '2',
            img : 'gitlab',
            positionsX : [0,12, 24,36 ,40,44,47,50,52],
            //positionsX : [0, 200, 400 , 500 ,580,630,680,730,800],
            rotates : [40,80,120,160,200,240,280,320,360 ],
            link :'https://gitlab.com/hbounouidar',
            title : "Public projects, you may find something interesting"
        },
        {
            id : '3',
            img : 'github',
            positionsX : [0,13, 26,39 ,44,49,55,61],
            // positionsX : [0,250, 500,750 ,850,950,1000,1050,1100],
            rotates : [40,80,120,160,200,240,280,300,360 ],
            link :'https://github.com/bounouidarhamza',
            title : "Private projects, but I'm here :)"
        },
    ])


    const range = [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75,0.85, 1];
    


      //****************
      const titles = [
          {
              label : 'Hamza BOUNOUIDAR',
              class : 'Head1'
          },
        //   {
        //       label : 'bounouidarhamza@gmail.com',
        //       class : 'Head3',
        //       href : true
        //   },
          {
              label : 'A software developer ! Build or grow a project </>',
              class : 'Head4'
          },
          {
              label : '(A problem) ? "OK, what is it about" : "let`s keep in touch"',
              class : 'Head4'
          }
      ];

    


      const trail = useTrail(titles.length, {
        x: 0,
        from: {  x: 50 },
       
      });

      const {top, left,transform} = useSpring({
        from: {top: 0, left: 0, transform : "rotate(45deg)"},
        top: 1,
        left: 1 ,
        transform:1,
        config: { duration: 4000 }
      });

      const fadeInAnim = useSpring({
        from: {opacity : 0},
        to: async (next, cancel) => {
          await next({opacity : 1})
        },
        config: { duration: 3000 }       
      });  
 
    
      //const transforms = [40,80,120,160,200,240,280,320,390 ]

     return (
        <React.Fragment>
            <div className={classes.Wrapper}>

                <animated.img className ={classes.Oim} style={fadeInAnim} src={Oim} alt="No trick just a photo !"/>
                <ContactForm></ContactForm>
                <div className={classes.Iam}>
            
                    {trail.map(({ x, ...rest }, index) => (
                        <animated.div
                            key={index}
                            className={classes[titles[index].class]}
                            style={{ ...rest, transform: x.interpolate(x => {
                                return `translateX(${x}%)`}) }}>

                            {titles[index].href ? <a href={"mailto:"+titles[index].label}>{titles[index].label}</a> : titles[index].label}
                        </animated.div>
                    ))}
        
                </div>
                
            </div>

            <animated.img className ={classes.Arrow} style={fadeInAnim} src={ArrowImg} alt="yes over here..."/>

           { items.map(item => {

                    return (
                        <a key={item.id} href={item.link} target="_blank" rel="noopener noreferrer" title={item.title}> 
                        <animated.div  style={{
                            top : top
                                .interpolate({range: range , output: [0, 68,40, 68,50,68,55,68,68]})
                                .interpolate(o => `${o}%`),
                            left : left
                                .interpolate({range: range , output: item.positionsX})
                                .interpolate(o => `${o}%`),
                            transform : transform
                                .interpolate({range: range , output: item.rotates})
                                .interpolate(r => {
                                   return  `rotate(${r}deg)`
                                }),
                               

                            'background' : `#161415 url(${images[item.img]}) no-repeat top center`,
                            'backgroundSize': 'cover',
                        }} className={classes.Contact}></animated.div></a>
                    )
                }                   
               
 
           )}
        </React.Fragment>
    )  

}

export default Contact;