import React,{useState,useEffect} from 'react';
import {useSpring, animated} from 'react-spring'


import {connect} from 'react-redux';

import classes from './Resume.module.css';
import Slider from '../../Slider/Slider';
import LogoAdmin from '../../../assets/images/logo-admin1.png'
import CodeBackground from '../../../assets/images/code.jpg';
import Map from '../../../component/Sections/Resume/Map/Map';
import ContentCloud from '../../../component/Sections/Resume/ContentCloud/ContentCloud';
import InfosSlide from '../../../component/Sections/Resume/InfosSlide/InfosSlide';
import Defs from '../../../component/Sections/Resume/Defs/Defs';
import ObjThreeD from '../../../component/Sections/Resume/ObjThreeD/ObjThreeD'

const Resume = (props)=> {

    const duration = 1500;

    const [show, setShow] = useState([false,false,false]);
  
  
    useEffect(() => {
        setTimeout(()=>{
            setShow([true,false,false]); 
            setTimeout(()=> {
                setShow([true,true,false]);
                setTimeout(()=> {
                    setShow([true,true,true]);
                },duration/3)
            },duration/3)
        },duration)
        
    }, [setShow]);


    const slideYAnim = useSpring({
        from: {transform: "translateY(50%)"},
        to: async (next, cancel) => {
          await next({transform: "translateY(10%)"})
          await next({transform: "translateY(0)"})
        },
        config: { duration: duration }       
      });


    
    const theCity = props.cities.filter(el => el.isActive);
        

        return (
                <div className={classes.Resume} style= {{
                    'background' : `#161415 url(${CodeBackground}) no-repeat top center`
                }}>
                  

                    <div className={classes.Wrapper}>
                        <Slider ></Slider>
                        <Defs />
                        <ObjThreeD /> 
                        <div className={classes.ThinkContainer}>
                            <animated.img style={slideYAnim} className={classes.Logo} src={LogoAdmin} alt = ""/>
                            { show[0] && <div  className={classes.Cloud1}></div>}
                            { show[1] && <div wait={2000} className={classes.Cloud2}></div>}
                            { show[2] && <div className={classes.Cloud3}>
                               {theCity.map(el => (
                                    <ContentCloud key ={el.name}
                                    theContent ={el.content} 
                                    contentImg={el.contentImg}
                                    imgName={el.imgName}
                                    />
                                    ))
                               } 
                                   

                            </div>}

                        </div>
                        <div className={classes.MapContainer}>
                            <Map />
                            {theCity.map(el => (
                               <InfosSlide
                                 key ={el.id}
                                 theName = {el.name}
                                 theImg = {el.imgCity}
                                />
                               
                                ))
                            } 
                        </div>

                    </div>
                </div>
        );
    
};


const mapStateToProps = state => {
    return {
       cities : state.resume.cities,
    }
}

export default connect(mapStateToProps)(Resume);
