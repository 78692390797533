import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import classes from "./NavigationItems.module.css";
import soundOffIcon from "../../../assets/images/icons/mute.png";
import soundOnIcon from "../../../assets/images/icons/unmute.png";
import backgrounMusic from "../../../assets/audio/sample.mp3";

const NavigationItems = (props) => {

  const [isMuted, setIsMuted] = useState(true);

  const audioRef = useRef(new Audio(backgrounMusic)); 

  
  const toggleSound = () => {
    setIsMuted((prevMuted) => {
      const newMuted = !prevMuted;
      if (newMuted) {
        audioRef.current.pause();
      } else {
        audioRef.current
          .play()
          .catch((error) => console.log("Playback error:", error));
      }
      return newMuted;
    });
  };

  return (
    <nav className={classes.NavigationContainer}>
      <ul className={classes.NavigationItems}>
        <li className={classes.NavigationItem}>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? classes.active : "")}
          >
            Home
          </NavLink>
        </li>
        <li className={classes.NavigationItem}>
          <NavLink
            to="/skills"
            className={({ isActive }) => (isActive ? classes.active : "")}
          >
            Skills
          </NavLink>
        </li>
        <li className={classes.NavigationItem}>
          <NavLink
            to="/projects"
            className={({ isActive }) => (isActive ? classes.active : "")}
          >
            Projects
          </NavLink>
        </li>
        <li className={classes.NavigationItem}>
          <NavLink
            to="/portfolio"
            className={({ isActive }) => (isActive ? classes.active : "")}
          >
            Portfolio
          </NavLink>
        </li>
        <li className={classes.NavigationItem}>
          <NavLink
            to="/contacts"
            className={({ isActive }) => (isActive ? classes.active : "")}
          >
            Contacts
          </NavLink>
        </li>
      </ul>

      <div
        className={classes.SoundToggle}
        onClick={toggleSound}
        aria-label="Toggle Sound"
      >
        <img src={isMuted ? soundOnIcon : soundOffIcon} alt="Toggle Sound" />
      </div>


    </nav>
  );
};

export default NavigationItems;
