import React from 'react';
// import ReactHtmlParser from 'react-html-parser'; 
import parse from 'html-react-parser' 
import  images from '../../../../assets/images/cloud';

import classes from './ContentCloud.module.css';
import  './ContentCloud.module.css';


const contentCloud = (props) => {
        
        return (
                <div className={classes.ContentCloud }
                >
                    {parse (props.theContent)}
                {  (props.contentImg) ? <img src={`${images[props.imgName]}`} alt="img content"/> : null} 

                </div>

        )




};

export default contentCloud; 