import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import EarthPlanet from '../../../../assets/threeD/earth.glb'

const ObjThreeD = () => {
    const mountRef = useRef(null);

    useEffect(() => {

        const mount = mountRef.current;

        // Scene, Camera, Renderer
        const scene = new THREE.Scene();
        scene.background = null;
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        mountRef.current.appendChild(renderer.domElement);

        // Lighting
        const ambientLight = new THREE.AmbientLight(0x404040, 2);
        scene.add(ambientLight);
        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight.position.set(1, 1, 1).normalize();
        scene.add(directionalLight);
        

        // debug
        // const gridHelper = new THREE.GridHelper(10, 10); // la grille
        // scene.add(gridHelper);
    
        // Axes
        // const axesHelper = new THREE.AxesHelper(5); 
        // scene.add(axesHelper);
    
        // const directionalLightHelper = new THREE.DirectionalLightHelper(directionalLight, 1); // Helper pour la lumière directionnelle
        // scene.add(directionalLightHelper);
    
        // const cameraHelper = new THREE.CameraHelper(camera); // Helper pour la caméra
        // scene.add(cameraHelper);


        const loader = new GLTFLoader();
        let daEarth;
        loader.load(EarthPlanet, (gltf) => {
            daEarth = gltf.scene;
            scene.add(daEarth);
        }, undefined, (error) => {
            console.error('An error happened while loading the React logo:', error);
        });


        camera.position.z = 5;
        let angle = 0;
        const radius = 5; 
        // Animation Loop
        const animate = () => {
            requestAnimationFrame(animate);

            if (daEarth) {
                
                daEarth.rotation.x += 0.01;
                daEarth.rotation.y += 0.01;

                angle += 0.003; // mon premier test de décalage..vitesse de rotation de la caméra
                camera.position.y = radius * Math.cos(angle)*4;
                //camera.position.z = radius * Math.sin(angle);
                camera.lookAt(daEarth.position);
            }

        

            renderer.render(scene, camera);
        };

        animate();

        // netoyage
        return () => {
            if (mount) {
                mount.innerHTML = ""; 
            }
            renderer.dispose();            
        };
    }, []);

    //return <div ref={mountRef} />;
    return <div ref={mountRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none' }} />;
};

export default ObjThreeD;
