import React from 'react';

import classes from './Defs.module.css';





const defs = (props) => (
    <React.Fragment>

        <div className={classes.Nutshell}>In a nutshell...</div>
        <div className={classes.Iam}>
            <span className={classes.Return}>return </span ><span style={{fontSize:"1vw"}}>{"{"}</span><br/>
                <span className={classes.Param} >who:</span> <span className={classes.Value} style={{fontSize:"2vw"}}>"HAMZA BOUNOUIDAR"</span><br/>
                <span className={classes.Param} >as:</span> <span className={classes.Value} style={{fontSize:"1.8vw"}} >"Software Engineer"</span><br/>
                <span className={classes.Param} >and:</span> <span className={classes.Value} style={{fontSize:"1.8vw"}}>"GIS Expert"</span><br/>
            <span style={{fontSize:"1vw"}}>{'}'}</span>
        </div>
    </React.Fragment>
)



export default defs;

