import React, { useState, useEffect } from "react";
import {  geoPath,geoMercator } from "d3-geo";
import { feature } from "topojson-client";
import {connect} from 'react-redux';
import {useSpring, animated} from 'react-spring'

import * as actions from '../../../../store/actions/index';
import classes from './Map.module.css';

import worlddata from '../../../../assets/data/world-50m.json';


const projection = geoMercator()
  .scale(1000) 
  .translate([400 , 900 ])
  //.translate([ 800 / 2, 450 / 2 ])
  const AnimFeTurbulence = animated('feTurbulence')
  const AnimFeDisplacementMap = animated('feDisplacementMap')

  
const WorldMap = (props) => {

  const [geographies, setGeographies] = useState([]);

  const {freq, scale, transform, opacity } = useSpring({
    from: { scale: 10, opacity: 0, transform: 'scale(0.9)', freq: '0.0175, 0.0' },
    to: { scale: 150, opacity: 1, transform: 'scale(1)', freq: '0.0, 0.0' },
    config: { duration: 3000 }
  })

  useEffect(() => {
/*     fetch("/world-50m.json")
      .then(response => {
        if (response.status !== 200) {
          console.log(`There was a problem: ${response.status}`)
          return
        }
        response.json().then(worlddata => {
          setGeographies(feature(worlddata, worlddata.objects.countries).features)
        })
      }) */
      setGeographies(feature(worlddata, worlddata.objects.countries).features)
  }, [])

  const handleCountryClick = countryIndex => {
    console.log("Clicked on country: ", geographies[countryIndex])
  }

  const handleMarkerClick = id => {
    props.onChangeCity(id);
  }

  return (
    <animated.svg style= {{transform, opacity }} width={ "100%" } height={ "100%" } viewBox="0 0 800 450">
        <defs>
          <filter id="water">
            <AnimFeTurbulence type="fractalNoise" baseFrequency={freq} numOctaves="1.5" result="TURB" seed="8" />
            <AnimFeDisplacementMap xChannelSelector="R" yChannelSelector="G" in="SourceGraphic" in2="TURB" result="DISP" scale={scale} />
          </filter>
        </defs>
      <g className="countries" filter="url(#water)">
        {
          geographies.map((d,i) => (
            <path
              key={ `path-${ i }` }
              d={ geoPath().projection(projection)(d) }
              className="country"
              fill={ `rgba(38,50,56,${ 1 / geographies.length * i})` }
              stroke="#ffffffc7"
              strokeWidth={ 0.5 }
              onClick={ () => handleCountryClick(i) }
            />
          ))
        }
      </g>
      <g className="markers">
        {
          props.cities.map((city, i) => (
            <React.Fragment key={ `marker-${i}` }>
                <circle
                
                cx={ projection(city.coordinates)[0] }
                cy={ projection(city.coordinates)[1] }
                r={city.isActive ? 12 : 8}             
                className= {"marker "+(city.isActive ? classes.ActiveMarker : classes.Marker )}
                onClick={ () => handleMarkerClick(city.id) }               
                />                
                <title>No Panic, just click it ;)</title>           
            </React.Fragment>
          ))
        }
      </g>
    </animated.svg>
  )
};

const mapStateToProps = state => {
  return {
     cities : state.resume.cities,
  }
};
const mapDispatchToProps = dispatch => {
  return {
      onChangeCity : (id) => dispatch(actions.changeCity(id))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(WorldMap);