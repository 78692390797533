import React, { useState } from 'react';

import { useForm } from "react-hook-form";

import classes from './ContactForm.module.css';
import axios from 'axios';

const ContactForm = () => {

    const { handleSubmit, register, formState} = useForm(
        { 
            mode: "onSubmit",
            reValidateMode : 'onBlur'
        }
    );

    const [name,setName] = useState('');
    const [message,setMessage] = useState('');
    const [email,setEmail] = useState('');
    const [buttonText,setbuttonText] = useState('SEND');


    const onSubmitHandler = (dataField, e) => {
        e.preventDefault();
        setbuttonText('...SENDING');
       

        let data = {
            name, email, message
        }

        axios.post('https://node-portfolio-hbo.herokuapp.com/api/v1', data)
        //axios.post('http://localhost:5000/api/v1', data)

        .then( res => {
           console.log(res);
            setbuttonText('MESSAGE SENT');
            resetForm();
        })
        .catch( (err) => {
          console.log('Message not sent')
        })
      }

     const resetForm = () => {
         setName('');
         setMessage('');
         setEmail('');
    }


    return (
        <form className={classes.FormControl} onSubmit={handleSubmit(onSubmitHandler)} >

          
        <label  htmlFor="message-name">Your Name *</label>
        <input 
                {...register("name", { required: "Your name is required" })}
                onChange={e => {setName(e.target.value);  }}
                className={formState.errors.name ? classes.Invalid : ''}
                name="name"  type="text" placeholder="..." value={name}
                />

        <label  htmlFor="message-email">Your Email *</label>
        <input 
                 {...register("email", {
                    required: "Your email is required",
                    pattern: {
                        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        message: "Invalid email address",
                    },
                })}
            onChange={(e) => {setEmail(e.target.value) ;  /* handleOnChange(e) */}} name="email"  type="email" placeholder="your@email.com"  value={email} 
                className={formState.errors.email ? classes.Invalid : ''} 
                 /> 

        <label  htmlFor="message-input">Your Message *</label>
        <textarea 
            {...register("message", { required: "Your message is required" })}
            onChange={e => {setMessage(e.target.value); /* handleOnChange(e) */}}
                className={formState.errors.message ? classes.Invalid : ''} 
                
                name="message" type="text" placeholder="Please write your message here" value={message} />
       
            <button type="submit" className={classes.Btn}>{ buttonText }</button>
            { !formState.isValid && formState.isSubmitted && 
                        <p className={classes.UserMsg+" "+classes.UserMsgError}>Please fill in all the required fields</p>}
        
        </form>
    )
}

export default ContactForm;