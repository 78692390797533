import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import { createRoot } from 'react-dom/client';

import { configureStore } from '@reduxjs/toolkit';
import * as serviceWorker from './serviceWorker';
import { combineReducers } from 'redux';
//import { compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';
//import createSagaMiddleware from 'redux-saga';

import './index.css';
import App from './App';
import resumeReducer from './store/reducers/resume';
import projectsReducer from './store/reducers/projects';
import portfolioReducer from './store/reducers/portfolio';
//import {watchResume } from './store/sagas/index';

//const composeEnhancers =process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;


const rootReducer = combineReducers({
    resume : resumeReducer,
    proj : projectsReducer,
    prtfo : portfolioReducer

});

//const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
    devTools: process.env.NODE_ENV !== 'production', // activer Redux DevTools en dev
  });
//sagaMiddleware.run(watchResume);

/* old
const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'));*/

const root = createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
    <BrowserRouter>
        <App />
    </BrowserRouter>
</Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
